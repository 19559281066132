<template>
  <div :style="{ width: width }" class="left-item no-boder-table h-full">
    <div class="seach-bar" style="position: relative" v-if="searchAble"></div>
    <div class="left-item-header">
      <div class="left-item-title truncate">
        <span style="font-size: 14px; line-height: 14px">{{ topTitle }}</span>
      </div>

      <div class="left-item-action">
        <i
            class="iconxinzeng2 iconfont"
            color="#0076ff"
            v-if="topAddable"
            @click="topAdd"
        />
      </div>
    </div>
    <p
        style="font-size: 12px; line-height: 13px; margin-top: -15px;text-align:left;"
        v-if="topSubTitle"
    >
      {{ topSubTitle }}
    </p>

    <div
        style="height: calc(100% - 45px)"
        class="overflow-y-auto no-scroll-bar"
    >
      <el-table
          ref="leftItem"
          :data="tableData"
          style="width: 100%; margin-bottom: 18px"
          class="bd-top"
          row-key="uuid"
          empty-text=" "
          :show-header="false"
          highlight-current-row
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          @row-click="rowClick"
      >
        <el-table-column>
          <template slot-scope="scope">
            <div class="w-full h-full truncate" style="cursor: pointer">
              <!-- 虚拟字段添加图标 -->
              <i
                  v-if="scope.row.type == 'append'"
                  class="iconshoudongrenwu-jinyong iconfont"
              ></i>
              <span style="margin-left: 10px">{{ scope.row[field] }}</span>
              <p
                  v-if="scope.row.code !== undefined"
                  style="line-height: 15px; margin-left: 10px"
              >
                {{ scope.row.code }}
              </p>

              <el-dropdown
                  v-if="(delAble || canCopy) && selectRow == scope.row"
                  style="position: absolute; right: 5px;"
                  :style="{
                  marginTop: scope.row.code !== undefined ? '-28px' : '0px',
                }"
              >
                <span class="el-dropdown-link">
                  <i class="iconfont iconbimgis_gengduo"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="delAble && scope.row.id > 0">
                    <p
                        @click.stop="
                        delModal = true;
                        delData = scope.row;
                      "
                    >
                      删除
                    </p>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="canCopy">
                    <p @click.stop="$emit('row-copy', scope.row)">复制</p>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-empty
          slot="empty"
          :image-size="90"
          v-if="tableData.length == 0 && emptyMode"
          description="暂无数据"
      ></el-empty>
    </div>
    <el-dialog title="提示" :visible.sync="delModal" width="25%">
      <span>是否删除</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delModal = false">取 消</el-button>
        <el-button type="primary" @click="delItem()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Empty,Dropdown,DropdownMenu, DropdownItem } from "element-ui";

export default {
  neme: "left-item",
  components:{
    'el-empty': Empty,
    'el-dropdown' : Dropdown,
    'el-dropdown-menu': DropdownMenu,
    'el-dropdown-item' : DropdownItem,
  },
  props: {
    tableData: {
      //总数据
      type: Array,
      default: () => [],
    },
    searchAble: {
      //搜索
      type: Boolean,
      default: true,
    },
    field: {
      //显示的字段
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
    defaultRow: {
      //默认选中
      type: [Object, String],
      default: "",
    },
    topTitle: {
      //顶部名称
      type: String,
      default: "",
    },
    topSubTitle: {
      type: String,
      default: "",
    },
    topAddable: {
      //顶部新增
      type: Boolean,
      default: true,
    },
    delAble: {
      //删除
      type: Boolean,
      default: false,
    },
    canCopy: {
      type: Boolean,
      default: false,
    },
    isChildren: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    defaultRow(data) {
      if (this.defaultRow) {
        this.selectRow = data;
        this.$emit("row-click", this.selectRow);
        this.$refs.leftItem.setCurrentRow(data);
      }
    },
  },
  data() {
    return {
      selectRow: "",
      delModal: false, //删除确认框
      delData: null,
      emptyMode: false,
    };
  },
  methods: {
    rowClick(row) {
      if (row.row_click !==undefined  && !row.row_click){
        return
      }
      this.selectRow = row;
      this.$emit("row-click", row);
    },
    topAdd() {
      //顶部新增
      this.$emit("top-add");
    },
    delItem() {
      //删除
      this.delModal = false;
      this.$emit("row-del", this.delData);
    },
  },
  mounted() {
    if (this.defaultRow) {
      this.selectRow = this.defaultRow;
      this.$emit("row-click", this.selectRow);
      this.$refs.leftItem.setCurrentRow(this.defaultRow);
    }
    setTimeout(() => {
      this.emptyMode = true;
    }, 500);
  },
};
</script>
<style lang="less" scoped>
.left-item-header {
  display: flex;
  justify-content: space-between;
  line-height: 16px;
  font-weight: 600;
  width: 100%;

  .left-item-title {
    width: calc(100% - 20px);
    height: 45px;
    line-height: 45px;
    text-align: left;
  }

  .left-item-action {
    cursor: pointer;
    line-height: 45px;

    i {
      margin-right: 5px;
    }
  }
}
</style>
<style>
.no-boder-table .el-table th.is-leaf,
.no-boder-table .el-table td {
  border: none !important;
}

.no-boder-table .el-table--group::after,
.no-boder-table .el-table--border::after,
.no-boder-table .el-table::before {
  display: none !important;
}
</style>
